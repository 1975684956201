//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'ContentContainer',
  props: {
    headline: {
      type: String,
      default: ''
    },
    content: {
      type: Array,
      default: () => []
    },
    omitSpacing: {
      type: Boolean,
      default: false
    }
  }
})
